<script>
  import containerCSS from "../../styles/container";
</script>

<div id="reason-wrapper">
  <div class={containerCSS}>
    <h2>
      Tietoturvakartoitus löytää riskit ja haavoittuvuudet ennen kuin on
      myöhäistä.
    </h2>
    <p id="reason-text">
      *Tietoturvan kehittämisen tuki eli niin kutsuttu ”tietoturvaseteli” on
      asetus, jonka Liikenne- ja viestintäministeriö julkaisi 1.12.2022. Tuki
      perustuu vuoden 2022 toiseen lisätalousarvioon, jossa myönnettiin 6
      miljoonan euron määräraha tietoturvallisuuden kehittämiseen suomalaisissa
      yrityksissä. Yritykset voivat käyttää tietoturvaseteleitä parantaakseen
      järjestelmiensä tietoturvaa ja kehittääkseen tietoturvaosaamistaan.
      Saatavilla ovat 15 000 € ja 100 000 € arvoiset tietoturvasetelit.
      Yhteiskunnan kyberturvallisuus on suurelta osin riippuvainen
      organisaatioiden kyvystä ennalta ehkäistä, estää ja rajoittaa
      kyberturvallisuusloukkauksia. Tästä lisätietoa:
      https://www.kyberturvallisuuskeskus.fi/fi/hae-tietoturvan-kehittamisen-tukea
    </p>
  </div>
</div>

<style>
  #reason-wrapper {
    background-color: var(--color-light-grey);
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: var(--color-movial-dark);
  }

  #reason-text {
    font-size: 15px;
  }
</style>
