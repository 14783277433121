<script>
  export let open = false;
  import containerCSS from "../../styles/container";
  import { scrollto } from "svelte-scrollto";
  const closeSlider = () => {
    open = false;
  };
</script>

<aside class:open>
  <div class={containerCSS}>
    <nav>
      <a
        href
        use:scrollto={{ element: "#about", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span> About us </span>
      </a>
      <a
        href
        use:scrollto={{ element: "#expertise", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Our expertise</span>
      </a>
      <a
        href
        use:scrollto={{ element: "#cybersecurity", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Cybersecurity</span>
      </a>
      <a
        href
        use:scrollto={{ element: "#softwareDevelopment", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Software development</span>
      </a>
      <a
        href
        use:scrollto={{ element: "#dataAnalytics", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Data science</span>
      </a>
      <a
        href
        use:scrollto={{ element: "#cases", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>References</span>
      </a>
      <a
        id="nav-culture"
        href
        use:scrollto={{ element: "#culture", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Company culture</span>
      </a>
      <a
        href
        use:scrollto={{ element: "#culture", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Careers</span>
      </a>
      <a
        href
        use:scrollto={{ element: "#branch", offset: -33 }}
        on:click={closeSlider}
        on:touchend={closeSlider}
      >
        <span>Contact</span>
      </a>
    </nav>
  </div>
</aside>

<style>
  aside {
    position: fixed;
    right: -100%;
    transition: right 0.3s ease-in-out;
    display: none;
    background-color: var(--color-movial-black);
  }

  .open {
    display: block;
    right: 0;
    bottom: 0;
    left: 0;
    top: 75px;
    z-index: 1000;
    height: 100vh;
    overflow: hidden;
  }

  nav {
    float: right;
    margin-top: 50px;
  }

  a {
    color: #fff;
    display: block;
    font-size: var(--font-size-xl);
    line-height: var(--line-height-normal);
    margin-bottom: 10px;
    text-decoration: none;
    text-align: right;
    position: relative;
  }

  span {
    text-align: center;
    display: inline;
    width: auto;
    position: relative;
  }

  span:hover {
    background-position: bottom;
    background-repeat: repeat no-repeat;
    background-size: 1em 0.5em;
    background-image: url("../../images/underline.svg");
  }

  span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    right: 0;
    background: #fff;
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: 0.25s linear;
  }
  span:hover:before,
  span:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }
</style>
