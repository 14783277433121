<script>
  import containerCSS from "../../styles/container";
  export let language = "en";
  export let purpose = "";

  document.addEventListener("DOMContentLoaded", function () {
    document
      .getElementById("terms")
      .setCustomValidity("Please accept the Terms of Use and Privacy Policy");
  });
</script>

<div id="contact-us-wrapper">
  <div class={containerCSS}>
    {#if language == "en"}
      <h2>How can we help?</h2>
      <p>
        Please fill in the form below and we will contact you as soon as
        possible.
      </p>
      <form
        name="insightly_web_to_lead"
        action="https://crm.na1.insightly.com/WebToLead/Create"
        method="post"
      >
        <input type="hidden" name="formId" value="HURKtSkI2dqn7FBtdbRjyg==" />
        <label for="insightly_organization">Company *</label>
        <input
          id="insightly_organization"
          name="OrganizationName"
          type="text"
        />
        <label for="insightly_firstName">First Name *</label>
        <input id="insightly_firstName" name="FirstName" type="text" />
        <label for="insightly_lastName">Last Name *</label>
        <input id="insightly_lastName" name="LastName" type="text" />
        <label for="email">Email </label>
        <input id="insightly_Email" name="email" type="text" />
        <label for="phone">Phone Number * </label>
        <input id="insightly_Phone" name="phone" type="text" />
        <input id="insightly_Title" name="Title" type="text" value={purpose} />
        <label for="Description">Description </label>
        <textarea id="insightly_Description" name="Description" type="text" />
        <input
          type="hidden"
          id="insightly_ResponsibleUser"
          name="ResponsibleUser"
          value="1904271"
        />
        <input
          type="hidden"
          id="insightly_LeadSource"
          name="LeadSource"
          value="3438649"
        />
        <div id="terms-wrapper">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onchange="this.setCustomValidity(validity.valueMissing ? 'Please accept the Terms of use and Privacy Policy' : '')"
            required
          />
          <label for="terms"
            >I have read and agree to the <a
              href="{window.location.origin}/terms-of-use"
              >Terms of Use and Privacy Policy</a
            >, and give my consent to the processing of my personal data as
            described in those policies</label
          >
        </div>
        <input type="submit" value="Submit" />
      </form>
    {:else}
      <h2>Miten voimme auttaa?</h2>
      <p>
        Täytä alla oleva lomake, niin otamme sinuun yhteyttä mahdollisimman
        pian.
      </p>
      <form
        name="insightly_web_to_lead"
        action="https://crm.na1.insightly.com/WebToLead/Create"
        method="post"
      >
        <input type="hidden" name="formId" value="HURKtSkI2dqn7FBtdbRjyg==" />
        <label for="insightly_organization">Yritys *</label>
        <input
          id="insightly_organization"
          name="OrganizationName"
          type="text"
        />
        <label for="insightly_firstName">Etunimi *</label>
        <input id="insightly_firstName" name="FirstName" type="text" />
        <label for="insightly_lastName">Sukunimi *</label>
        <input id="insightly_lastName" name="LastName" type="text" />
        <label for="email">Sähköpostiosoite </label>
        <input id="insightly_Email" name="email" type="text" />
        <label for="phone">Puhelinnumero *</label>
        <input id="insightly_Phone" name="phone" type="text" />
        <input id="insightly_Title" name="Title" type="text" value={purpose} />
        <label for="Description">Kuvaus </label>
        <textarea id="insightly_Description" name="Description" type="text" />
        <input
          type="hidden"
          id="insightly_ResponsibleUser"
          name="ResponsibleUser"
          value="1904271"
        />
        <input
          type="hidden"
          id="insightly_LeadSource"
          name="LeadSource"
          value="3438649"
        />
        <div id="terms-wrapper">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            onchange="this.setCustomValidity(validity.valueMissing ? 'Please accept the Terms of use and Privacy Policy' : '')"
            required
          />
          <label for="terms"
            >I have read and agree to the <a
              href="{window.location.origin}/terms-of-use"
              >Terms of Use and Privacy Policy</a
            >, and give my consent to the processing of my personal data as
            described in those policies</label
          >
        </div>
        <input type="submit" value="Lähetä" />
      </form>
    {/if}
  </div>
</div>

<style>
  #terms-wrapper {
    display: flex;
    padding: 15px 0 15px 0;
  }
  #terms-wrapper input[type="checkbox"] {
    position: relative;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    margin: 0 0.6em 0 0;
    line-height: 0;
    outline: 0;
    padding: 0;
    height: var(--space-m);
    width: var(--space-m);
  }
  #terms-wrapper label {
    font-size: var(--font-size-s);
    margin-left: var(--space-s);
  }

  #contact-us-wrapper {
    background-color: var(--color-light-grey);
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: var(--color-movial-dark);
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  input[type="text"] {
    width: 500px;
    max-width: 100%;
  }

  input[type="submit"] {
    display: block;
    background-color: var(--color-movial-dark);
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: var(--font-size-s);
    cursor: pointer;
  }

  #insightly_Title {
    display: none;
  }

  textarea {
    width: 500px;
    height: 200px;
    display: block;
  }
</style>
