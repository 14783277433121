<script>
  import containerCSS from "../../styles/container";
</script>

<div id="intro">
  <div class={containerCSS}>
    <h1>Boost Productivity with Workflow Automation</h1>
    <div id="proposition">
      <p>Would you benefit from collaboration and connectivity?</p>
      <a href="#contact-section">What's the best way to reach you?</a>
    </div>
  </div>
</div>

<style>
  #intro {
    background-color: #282b51;
    background-image: url("../../workflow-automation/images/background.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 85%;
    padding-bottom: 70px;
    height: 75vh;
    display: flex;
    align-items: center;
  }

  a {
    background: #1dc0ed 0 0 no-repeat padding-box;
    border-radius: 45px;
    opacity: 1;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 1;
    padding: 22px 44px;
    font-weight: bold;
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 80px;
    margin-left: 10%;
  }

  h1 {
    font-size: var(--font-size-xxl);
    margin-bottom: var(--space-xl);
    line-height: 125%;
  }

  #proposition {
    position: relative;
    max-width: 600px;
    color: #5de1ff;
  }

  p {
    font-size: 25px;
    padding: 0;
    margin: 0;
    padding-left: 10%;
  }

  @media (min-width: 1000px) {
    h1 {
      width: 60%;
    }
  }

  @media (min-width: 1400px) {
    #intro {
      background-image: url("../../workflow-automation/images/background@2x.png");
    }
  }
</style>
