<script>
  export let open = false;
</script>

<button class:open on:click={() => (open = !open)}>
  <svg>
    <line class="top" x1="0" y1="2" x2="32" y2="2" />
    <line class="middle" x1="0" y1="12" x2="24" y2="12" />
    <line class="bottom" x1="0" y1="22" x2="32" y2="22" />
  </svg>
</button>

<style>
  svg {
    width: 32px;
    height: 24px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }

  svg line {
    stroke: #fff;
    stroke-width: 3;
    transition: transform 0.3s ease-in-out;
  }

  button {
    z-index: 20;
    padding: 0;
    background: none;
    border: none;
  }

  .open svg {
    transform: scale(0.7);
  }

  .open .top {
    transform: translate(6px, 0px) rotate(45deg);
  }

  .open .middle {
    opacity: 0;
  }

  .open .bottom {
    transform: translate(-12px, 9px) rotate(-45deg);
  }
</style>
