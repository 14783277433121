<script>
  import Hamburger from "./Hamburger.svelte";
  import Logo from "./Logo.svelte";
  import containerCSS from "../../styles/container";

  export let sidebar = false;
</script>

<header>
  <div class={containerCSS}>
    <nav>
      <div>
        <Logo />
      </div>
      <div>
        <Hamburger bind:open={sidebar} />
      </div>
    </nav>
  </div>
</header>

<style>
  header {
    position: fixed;
    top: 0;
    padding-top: 25px;
    width: 100%;
    height: 50px;
    background-color: var(--color-movial-black);
  }
  nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
</style>
