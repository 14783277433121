<script>
  import containerCSS from "../../styles/container";
</script>

<div id="intro">
  <div class={containerCSS}>
    <h1>Does your system hold up?</h1>
    <div id="proposition">
      <img
        src="../../images/vulnerability-assessment/arrow.png"
        alt="arrow pointing right"
      />
      <p>
        Vulnerability assessments and continuous monitoring will enhance your
        security - like locking the door and turning the alarm on.
      </p>
    </div>
  </div>
</div>

<style>
  #intro {
    background: none;
    padding-bottom: 70px;
  }

  h1 {
    font-size: var(--font-size-xxl);
    padding-top: var(--space-xxxl);
    margin-bottom: var(--space-xxl);
  }

  #proposition {
    position: relative;
    padding: 20px;
    background-color: black;
    max-width: 600px;
    margin-left: 150px;
  }

  #proposition img {
    position: absolute;
    top: 43px;
    left: -94px;
    width: 80px;
  }

  p {
    color: #1dc0ed;
    font-size: 22px;
    padding: 0;
    margin: 0;
  }
</style>
