<script>
  import Movial from "./pages/Movial.svelte";
  import VulnerabilityAssessment from "./pages/VulnerabilityAssessment.svelte";
  import IndustrialMobileApplications from "./pages/IndustrialMobileApplications.svelte";
  import Tietoturvaseteli from "./pages/Tietoturvaseteli.svelte";
  import Terms from "./pages/Terms.svelte";
  import ThankYou from "./components/thankyou/ThankYou.svelte";

  let currentPage = landingPage;

  const urlParams = new URLSearchParams(window.location.search);
  const isThanks = urlParams.has("thankyou");
</script>

{#if isThanks}
  <section id="thanks"><ThankYou /></section>
{:else if currentPage == "movial"}
  <Movial />
{:else if currentPage == "industrialMobileApplications"}
  <IndustrialMobileApplications />
{:else if currentPage == "vulnerabilityAssessment"}
  <VulnerabilityAssessment />
{:else if currentPage == "tietoturvaseteli"}
  <Tietoturvaseteli />
{:else if currentPage == "terms-of-use"}
  <Terms />
{/if}
