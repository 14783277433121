<script>
    import containerCSS from "../../styles/container";
    import { col2_1000 } from "../../styles/cols";
  </script>
  
  <div id="credential-wrapper">
    <div class={containerCSS}>
      <div id="credential" class={col2_1000}>
        <div id="best-practices">
          <div class="credential-content">
            <p>
              For your ease-of-mind, we follow standards and best practices such
              as:
            </p>
            <ul>
              <li>OWASP Application Security Verification Standard (ASVS 4.0)</li>
              <li>OWASP Top 10 Web Application Security Risks</li>
              <li>OWASP Python Security Project</li>
              <li>Common Vulnerability Scoring System (CVSS 3.0)</li>
              <li>STRIDE threat modelling framework</li>
              <li>SEI CERT Secure Coding Standards</li>
              <li>Coding Rules for Android, C, C++, Java</li>
            </ul>
          </div>
        </div>
  
        <div id="certificates">
          <div class="credential-content">
            <p>
              Certifications held by the Movial cybersecurity expert team include:
            </p>
            <ul>
              <li>Offensive Security Certified Professional (OSCP)</li>
              <li>Offensive Security Wireless Professional (OSWP)</li>
              <li>Offensive Security Web Expert (OSWE)</li>
              <li>Clarified Security Hands-On Hacking Essentials</li>
              <li>Clarified Security Hands-On Hacking Advanced</li>
              <li>Clarified Security Web Application Security</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <style>
    #credential-wrapper {
      background-color: var(--color-movial-black);
      background: url(../../images/people.jpg) no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-bottom: var(--space-xl);
      padding-top: var(--space-xl);
    }
  
    #credential ul {
      padding: 0px;
    }
  
    #credential li {
      background: url("../../images/vulnerability-assessment/Bullet-arrow.png")
        no-repeat left center;
      padding: 7px 0px 7px 40px;
      list-style: none;
      margin: 0;
      vertical-align: middle;
  
      margin-bottom: 10px;
      font-size: 18px;
    }
  
    .credential-content {
      padding-right: 40px;
    }
  </style>
  