<script>
  import { scrollto } from "svelte-scrollto";
  export let scroll = true;
</script>

<div>
  {#if scroll}
    <a href use:scrollto={{ element: "body" }}>
      <img src="../../images/movial-logo.svg" alt="Movial" />
    </a>
  {:else}
    <a href="/">
      <img src="../../images/movial-logo.svg" alt="Movial" />
    </a>
  {/if}
</div>

<style>
  img {
    width: 134px;
  }
</style>
