<script>
  import containerCSS from "../../styles/container";
</script>

<div id="thank-you">
  <div class={containerCSS}>
    <h1>Thank You!</h1>
    <p>We will get back to you as soon as possible.</p>
    <h1>Kiitos!</h1>
    <p>Otamme sinuun pian yhteyttä.</p>
  </div>
</div>

<style>
  #thank-you {
    text-align: center;
    padding-top: 20vh;
  }
</style>
