<script>
  import containerCSS from "../../styles/container";
</script>

<div id="intro">
  <div class={containerCSS}>
    <h1>Engineering secure software and excellent experiences</h1>
    <p>
      We’re on a mission to help pioneering businesses shape their future with
      innovative, thoughtful and secure software solutions.
      <br /><br />
      Since 2001, we’ve partnered with forward-thinking businesses to build custom
      software development solutions – from multimedia and communications systems
      to world-class mobile and embedded services.
    </p>
  </div>
</div>

<style>
  #intro {
    background: none;
    padding-bottom: 70px;
  }

  h1 {
    font-size: var(--font-size-xxl);
    padding-top: var(--space-xxxl);
  }

  p {
    font-size: var(--font-size-l);
  }
</style>
