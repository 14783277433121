import { css } from "@emotion/css";

const breakpoints = [400, 1000, 1600];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const container = css`
  margin-left: 7%;
  margin-right: 7%;

  ${mq[0]} {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  ${mq[1]} {
    margin-left: auto;
    width: 80%;
  }
  ${mq[2]} {
    margin-left: auto;
    width: 70%;
  }
`;

export default container;
