<script>
  import containerCSS from "../../styles/container";
</script>

<div id="intro">
  <div class={containerCSS}>
    <h1>Tietoturvakartoitus</h1>
    <p>
      Tietoturvasetelillä* parannat huoltovarmuutta ja se alkaa kartoituksesta.
    </p>
  </div>
</div>

<style>
  h1 {
    font-size: var(--font-size-xl);
  }

  #intro {
    background-color: #282b51;
    background-image: url("../../tietoturvaseteli/images/background.jpg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    height: 60vh;
    display: flex;
    align-items: center;
  }
  p {
    font-size: var(--font-size-l);
  }

  @media (min-width: 1000px) {
    h1 {
      font-size: var(--font-size-xxxl);
    }
  }
</style>
