<script>
  import containerCSS from "../../styles/container";
  import { col3_1000 } from "../../styles/cols";
</script>

<div id="examples-wrapper">
  <div class={containerCSS}>
    <h2>Examples of industry specific projects:</h2>
    <div id="examples" class={col3_1000}>
      <div class="example-content">
        <img
          src="../../workflow-automation/images/building-maintenance.png"
          alt="Building maintenance"
        />
        <h3>Remote Team Management</h3>
      </div>
      <div class="example-content">
        <img
          src="../../workflow-automation/images/fire-detection.png"
          alt="Fire detection"
        />
        <h3>Manual Task Automation</h3>
      </div>
      <div class="example-content">
        <img
          src="../../workflow-automation/images/sales-workflow.png"
          alt="Sales workflow"
        />
        <h3>Sales Workflow</h3>
      </div>
    </div>
  </div>
</div>

<style>
  #examples-wrapper {
    background-color: #282b51;
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: #fff;
    text-align: center;
  }

  #examples > div {
    text-align: center;
  }

  h2 {
    margin-bottom: var(--space-xl);
  }

  #examples img {
    width: 60%;
  }
</style>
