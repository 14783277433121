// Configuration object for vanilla-cookieconsent
export default {
  autorun: true,
  current_lang: "en",
  autoclear_cookies: true,
  page_scripts: true,
  mode: "opt-in",
  cookie_name: "website_usage_social_media",
  cookie_expiration: 182, // (days)
  onFirstAction: function (user_preferences, _cookie) {
    if (user_preferences.accept_type === "necessary") {
      gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    } else if (user_preferences.accept_type === "all") {
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
  },
  onAccept: function (_cookie) {
    // load somescript, google analytics ...
  },
  onChange: function (_cookie, _changed_preferences) {
    // cleanup logic ... (e.g. disable gtm if analytics category is disabled)
  },
  gui_options: {
    consent_modal: {
      layout: "cloud", // box/cloud/bar
      position: "bottom center", // bottom/middle/top + left/right/center
      transition: "slide", // zoom/slide
      swap_buttons: false, // enable to invert buttons
    },
    settings_modal: {
      layout: "box", // box/bar
      transition: "slide", // zoom/slide
      // position: 'left',           // left/right
    },
  },
  languages: {
    en: {
      consent_modal: {
        title: "We use cookies!",
        description:
          'This website uses essential cookies to ensure its proper operation and advertising cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: "Accept all",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Reject all",
          role: "accept_necessary", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Cookie preferences",
        save_settings_btn: "Save settings",
        accept_all_btn: "Accept all",
        reject_all_btn: "Reject all",
        close_btn_label: "Close",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Expiration" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "Cookie usage 📢",
            description:
              'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="terms-of-use" class="cc-link">privacy policy</a>.',
          },
          {
            title: "Strictly necessary cookies",
            description:
              "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          // Put LinkedIn Insight Tag stuff here
          {
            title: "Advertisement and Targeting cookies",
            description:
              "These cookies allow us to show you targeted ads based on your browsing behavior, interests, and demographics. They help us understand how effective our advertising campaigns are and to improve them over time. By accepting these cookies, you consent to the collection and use of your data for targeted advertising purposes.",
            toggle: {
              value: "advertising",
              enabled: false,
              readonly: false,
            },
          },
          {
            title: "More information",
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#branch">contact us</a>.',
          },
        ],
      },
    },
  },
};
