<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
</script>

<div id="data-analytics-page">
  <div class={containerCSS}>
    <h2 id="main-title">Movial Data Science and You</h2>
    <p>
      How do you improve your customer experience, optimize processes, and make
      right business decisions? By drawing insights from data through
      structuring and mining—in this process Movial is your partner.
    </p>
    <p>
      How do we do it? By helping you plan <strong>what type of data</strong>
      needs to be collected and <strong>how it can be used</strong>.
      Personalising content based on your customer behaviour and profiling, we
      can identify high-value customers and harness machine learning to uncover
      new insights from existing data.
    </p>
    <div id="da-items" class={col2_1000}>
      <div>
        <h3>Customer experience</h3>
        <p>
          Netflix, Apple’s Siri, Amazon’s Alexa and Google search; these
          companies use data science techniques behind the scenes to help
          improve their customer experience. We are keenly watching this space
          for ideas and inspiration to help your organization as well.
        </p>
      </div>

      <div>
        <h3>Service design</h3>
        <p>
          When designing digital services we consider both your business goals
          and your users' (UX) experience. The goal is twofold, to design a
          service/system that not only contributes value to your business but
          also reduces customer confusion.
        </p>
      </div>

      <div>
        <h3>IoT is a force multiplier</h3>
        <p>
          Suppose your organization has repetitive, autonomous, remote,
          high-risk processes that could be done using less human work, there’s
          nothing more suited for the job than devices that can communicate with
          each other and over the network. <br /><br />The result is cost
          reduction, quality improvement, stability, and predictability for your
          business operations.
        </p>
      </div>

      <div>
        <h3>Data warehousing</h3>
        <p>
          Storing data securely and efficiently is an important task. At Movial,
          we build an emerging data management architecture called
          <strong>Data Lakehouse</strong>, which combines the flexibility of a
          data lake of your raw data with the management capabilities of a data
          warehouse.
        </p>
      </div>

      <div>
        <h3>Machine learning</h3>
        <p>
          Modern AI uses the Machine Learning (ML). In short, data provided by
          you is used for training ML models to learn the patterns hidden in the
          data. <br /><br />Depending on different use cases Movial can classify
          the problem into 3 main categories providing an easier view of what
          can be done with the existing data. This allows you to come up with
          better direction on planning AI applications from the beginning.
        </p>
      </div>

      <div>
        <h3>User interface (UI) development</h3>
        <p>
          If you make software that people love, they’ll use it. Our team has
          extensive experience in building human-centered, highly functional and
          lag-free user interfaces that are customized to different device types
          using the best native or cross-platform technology for each case. From
          touchscreen, industrial and cross-device interfaces to things like
          high-performance 3D visualizations, we can help you create a
          world-class user experience.
        </p>
      </div>

      <div>
        <h3>Descriptive & Predictive analytics</h3>
        <p>
          When the complexity of the problem is out of the ability of AI models
          and requires human decision, Movial provides an analytic service to
          assist your business in making predictions.<br /><br />An in-depth
          look inside the data with explainable variables/ features helps the
          decision-maker quickly try and iterate the strategy.
        </p>
      </div>

      <div>
        <h3>Data visualization</h3>
        <p>
          Data visualization is essential for you to make data-driven decisions.
          The plainest graph could be too boring yet the most stunning
          visualization could utterly fail in making the point clear.<br /><br
          />
          Movial offers a wide range of visualization options: from static reports,
          interactive charts to dynamic dashboard. The visualization could be integrated
          directly into your website with real-time updates—no need to have a separate
          platform for business intelligent.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  #data-analytics-page {
    background-color: var(--color-movial-black);
    background: url(../../images/people.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  h2#main-title {
    padding-top: var(--space-xxl);
  }

  h3 {
    font-size: var(--font-size-m);
    margin-bottom: var(--space-xs);
  }

  #da-items p {
    font-size: var(--font-size-s);
    margin-top: 0px;
  }

  @media (min-width: 1000px) {
    #da-items > div {
      padding-right: var(--space-xl);
    }
  }
</style>
