<script>
  import SimpleHeader from "../components/header/SimpleHeader.svelte";
  import Intro from "../components/terms-of-use/Intro.svelte";
  import TermsOfUse from "../components/terms-of-use/TermsOfUse.svelte";
  import Branch from "../components/branch/Branch.svelte";
  const branches = ["FI"];
  const language = "en";
  const purpose = "Terms of Use";
</script>

<SimpleHeader />
<div id="sections">
  <section id="intro-section"><Intro /></section>
  <section id="terms-section"><TermsOfUse /></section>
  <section id="branch-section"><Branch theme="dark" {branches} /></section>
</div>
