<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
</script>

<div id="reason-wrapper">
  <div class={containerCSS}>
    <div class={col2_1000}>
      <div id="reason">
        <p>
          Workflow automation increases productivity, improves communication,
          reduces human error and facilitates access to critical internal
          information. You will gain valuable insight into the inner workings of
          your business, making it easy to identify problem areas and
          immediately see results with targeted improvements.
        </p>
      </div>
      <div id="services">
        <div class="service-content">
          <h2>Boost Productivity</h2>
          <p>
            Put critical information directly into the hands of your employees
            wherever they are so they can get the job done.
          </p>
        </div>

        <div class="service-content">
          <h2>Increase Transparency</h2>
          <p>
            Visualize internal processes holistically, understand productivity
            bottlenecks and implement solutions that make a direct impact for
            your business.
          </p>
        </div>

        <div class="service-content">
          <h2>Improve Workflow & Connectivity</h2>
          <p>
            Our human centric user interface design will improve efficiency and
            increase productivity in your workflow applications.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  #reason-wrapper {
    background-color: var(--color-light-grey);
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: var(--color-movial-dark);
  }

  #reason p {
    width: 90%;
    font-size: var(--font-size-l);
    margin-top: 0px;
  }

  #services h2 {
    font-size: var(--font-size-l);
  }

  #services p {
    font-size: var(--font-size-m);
  }
</style>
