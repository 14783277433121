<script>
  import containerCSS from "../../styles/container";
</script>

<div id="terms-of-use__wrapper">
  <div class={containerCSS}>
    <div id="terms-of-use">
      <section>
        <h2>Movial company Privacy Policy</h2>
        <p>
          Movial Oy and affliates. This privacy policy will explain how our
          organization uses the personal data we collect from you when you use
          our website.
        </p>
        <h5 id="terms-of-use__topics">Topics:</h5>
        <ul class="terms-of-use__list">
          <li><a href="#data-collection">What data do we collect?</a></li>
          <li>
            <a href="#how-do-we-collect-data">How do we collect your data?</a>
          </li>
          <li>
            <a href="#how-we-will-use-data">How will we use your data?</a>
          </li>
          <li>
            <a href="#how-do-we-store-your-data">How do we store your data?</a>
          </li>
          <li>
            <a href="#what-are-your-data-protection-rights"
              >What are your data protection rights?</a
            >
          </li>
          <li><a href="#what-are-cookies">What are cookies?</a></li>
          <li><a href="#how-we-use-cookies">How do we use cookies?</a></li>
          <li>
            <a href="#what-types-of-cookies-do-we-use"
              >What types of cookies do we use?</a
            >
          </li>
          <li>
            <a href="#how-do-we-manage-your-data"
              >How do we manage your cookies?</a
            >
          </li>
          <li>
            <a href="#privacy-policy-of-other-websites"
              >Privacy policies of other websites</a
            >
          </li>
          <li>
            <a href="#changes-to-our-privacy-pollicy"
              >Changes to our privacy policy</a
            >
          </li>
          <li><a href="#how-to-contact-us">How to contact us</a></li>
          <li>
            <a href="#how-to-contact-the-appropriate-authorities"
              >How to contact the appropriate authorities</a
            >
          </li>
        </ul>
      </section>
      <section id="data-collection">
        <h4>What data do we collect?</h4>
        <p>Movial collects the following data:</p>
        <ul class="terms-of-use__list">
          <li>
            Personal identification information (name, e-mail address, phone
            number, etc.)
          </li>
        </ul>
      </section>
      <section id="how-do-we-collect-data">
        <h4>How do we collect your data?</h4>
        <p>
          You directly provide Movial with most of the data we collect. We
          collect data and process data when you:
        </p>
        <ul>
          <li>Send a contact form for any of our services</li>
          <li>Use or view our website via your browser's cookies</li>
        </ul>
        <p>
          Movial may also receive your data indirectly from the following
          sources:
        </p>
        <ul>
          <li>Viewing of our postings from LinkedIn</li>
          <li>Viewing of our website via your browser links from Google</li>
        </ul>
      </section>
      <section id="how-we-will-use-data">
        <h4>How will we use your data?</h4>
        <p>Movial collects your data so that we can:</p>
        <ul>
          <li>Respond to your contact form per your request</li>
          <li>
            E-mail you further details on services we think you might be
            interested in
          </li>
        </ul>
      </section>
      <section id="how-do-we-store-your-data">
        <h4>How do we store your data?</h4>
        <p>
          Movial securely stores your data at its premises for a period relevant
          to its use and once this period has expired, will delete your data.
        </p>
      </section>
      <section>
        <h4>Marketing</h4>
        <p>
          Movial would like to send you information about our services that we
          think you might like and have expressed interest in prior. You have
          the right at anytime to stop Movial from contacting you for marketing
          purposes.
        </p>
        <p>
          If you no longer wish to be contacted marketing purposes, please <a
            href="mailto:email@movial.com">email@movial.com.</a
          >
        </p>
        <ul id="linkedin-notice">
          <li>
            <b
              >This site uses the LinkedIn Insight Tag to help us understand how
              our website is being used and to enable targeted advertising on
              LinkedIn.</b
            >
          </li>
        </ul>
      </section>
      <section id="what-are-your-data-protection-rights">
        <h4>What are your data protection rights?</h4>
        <p>
          Movial would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <ul>
          <li>
            The right to access - you have the right to request Movial for
            copies of your personal data. We may charge you a small fee for this
            service.
          </li>
          <li>
            The right to rectification - you have the right to request that
            Movial correct any information you believe is inaccurate. You also
            have the right to request Movial to complete information you believe
            is incomplete.
          </li>
          <li>
            The right to erasure - you have the right to request that Movial
            erase your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing - you have the right to object to
            Movial's processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to object to processing- you have the right to object to
            Movial's processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to data portability - you have the right to request that
            Movial transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </li>
          <li>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us at our
            e-mail: <a href="mailto:email@movial.com">email@movial.com.</a>
          </li>
        </ul>
      </section>
      <section id="what-are-cookies">
        <h4>What are cookies?</h4>
        <p>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our websites, we may collect information from you automatically
          through cookies or similar technology.
        </p>
        <p>
          For further information, visit <a href="https://allaboutcookies.org/"
            >allaboutcookies.org.</a
          >
        </p>
      </section>
      <section id="how-we-use-cookies">
        <h4>How do we use cookies?</h4>
        <p>
          Movial uses cookies in a range of ways to improve your experience on
          our website including:
        </p>
        <ul>
          <li>Understanding how you use our website</li>
          <li>Understanding how you landed on our website</li>
        </ul>
        <section id="what-types-of-cookies-do-we-use">
          <h4>What type of cookies do we use?</h4>
          <p>
            There are a number of different types of cookies, however, our
            website uses:
          </p>
          <ul>
            <li>
              Functionality - we use these cookies so that we recognize you on
              our website and remember your previously selected preferences.
              These could include what language you prefer and location you are
              in. A mix of first party and 3rd party cookies are used.
            </li>
            <li>
              Advertising - we use these cookies to collect information about
              your visit to our website, the content you viewed, the links you
              followed and information about your browser, device, and your IP
              address. We sometimes share some limited aspects of this data with
              third parties for advertising purposes. We may also share online
              data collected through cookies with our advertising partners. This
              means that when you visit another website, you may be shown
              advertising based on your browsing patterns on our website.
            </li>
          </ul>
        </section>
        <section id="how-do-we-manage-your-data">
          <h4>How to manage cookies?</h4>
          <p>
            You can set your browser not to accept cookies, and the above
            website tells you how to remove cookies from your browser. However,
            in a few cases, some of our website features may not function as a
            result.
          </p>
        </section>
        <section id="privacy-policy-of-other-websites">
          <h4>Privacy policies of other websites</h4>
          <p>
            If our website contains links to other websites, our privacy policy
            applies only to our website, so if you click on a link to another
            website, you should read their privacy policy.
          </p>
        </section>
        <section id="changes-to-our-privacy-pollicy">
          <h4>Changes to our privacy policy</h4>
          <p>
            We keep our privacy policy under regular review and place any
            updates on this web page. This privacy policy was last updated on
            May 1st 2023.
          </p>
        </section>
        <section id="how-to-contact-us">
          <h4>How to contact us</h4>
          <p>
            If you have any questions about Movial's privacy policy, the data we
            hold on you, or you would like to exercise one of your data
            protection rights, please do not hesitate to contact us.
          </p>
        </section>
        <section id="how-to-contact-the-appropriate-authorities">
          <h4>How to contact the appropriate authority</h4>
          <p>
            Should you wish to report a complaint or if you feel that Movial has
            not addressed your concern in a satisfactory manner, you may contact
            the Information Commissioner’s Office; tietosuoja.fi
          </p>
        </section>
      </section>
    </div>
  </div>
</div>

<style>
  #terms-of-use__wrapper {
    background-color: var(--color-light-grey);
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: var(--color-movial-black);
  }

  #terms-of-use ul li {
    font-size: var(--font-size-s);
  }

  #terms-of-use p {
    padding: 0;
    width: 90%;
    font-size: var(--font-size-s);
  }

  .terms-of-use__list {
    margin: 0;
  }

  #terms-of-use__topics {
    margin: 0 0 10px 0;
    padding: 0;
  }

  #linkedin-notice {
    margin: 0;
    padding: 15px;
  }

  #what-are-your-data-protection-rights ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  #what-are-your-data-protection-rights ul li,
  how-we-use-cookies,
  ul li {
    padding: 5px;
  }
</style>
