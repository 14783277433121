<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";

  window.addEventListener("scroll", function () {
    const element = document.getElementById("culture-wrapper");
    const elementOffsetTop = element.offsetTop;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollOffset = scrollTop - elementOffsetTop;

    if (scrollOffset > -1300) {
      const posY = scrollOffset / 6;
      element.style.backgroundPositionY = posY + "px";
    }
  });
</script>

<div id="culture-wrapper">
  <div class={containerCSS}>
    <h2>Life and work at Movial</h2>
    <div class={col2_1000}>
      <div>
        <div class="col-content">
          <h3>Working together – from concept to code</h3>
          <p>
            Founded in 2001, Movial is on a mission to help pioneering
            businesses shape their future with innovative, thoughtful and secure
            software solutions. We’re a diverse team of software development
            experts working in Finland, Romania and the US and with companies of
            all sizes, all across the globe.
          </p>
          <p>
            At Movial, we build innovative software solutions by building great
            relationships with our clients and our colleagues. We understand
            their challenges, their needs and their desire to create software
            and services that delight. And we’re passionate about using our
            skills to help them do that.
          </p>
          <p>
            Our clients and their needs are at the heart of everything we do.
            But we’re also dedicated to help every person at Movial to maximize
            their own potential. We believe that trust, respect and being part
            of a supportive community are keys to our success – as individuals,
            as a company, and together with our clients.
          </p>
        </div>
      </div>
      <div>
        <div class="col-content">
          <h3>Come work with us</h3>
          <p>We're looking for great people to join our growing team.</p>
          <a id="openings-link" href="https://movial.breezy.hr/"
            >View openings</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  #culture-wrapper {
    background-color: var(--color-movial-black);
    background-image: url("../../images/people2.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    padding-bottom: var(--space-l);
  }

  h2 {
    padding-top: var(--space-xxl);
  }

  p {
    font-size: var(--font-size-m);
  }

  a {
    background-color: rgba(255, 255, 255, 0.25);
    padding: var(--space-m);
    border-radius: 5px;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
  }

  @media (min-width: 1000px) {
    .col-content {
      padding-right: var(--space-xxl);
    }
  }
</style>
