<script>
  import ExpertiseItem from "./ExpertiseItem.svelte";
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
</script>

<div id="expertise">
  <div class={containerCSS}>
    <h2>Our expertise</h2>
    <div class={col2_1000}>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/security.png"
            alt="Cybersecurity"
          />
          <span slot="title">Cybersecurity</span>
          <span slot="description">
            Expert design and implementation of custom software that is
            fine-tuned for optimal security and privacy during development.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/software.png"
            alt="Software Development"
          />
          <span slot="title">Software Development</span>
          <span slot="description">
            World-renowned experts in customer open source design and
            optimization.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/ux.png"
            alt="User Experience (UX) Design"
          />
          <span slot="title">User Experience (UX) Design</span>
          <span slot="description">
            Consistent omnichannel design experiences for service and device
            users based on detailed consumer insight.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/ui.png"
            alt="User Interface (UI) & Interaction Design"
          />
          <span slot="title">User Interface (UI) & Interaction Design</span>
          <span slot="description">
            Multi-channel interface design for interactions that have the
            potential to reach millions of users.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img slot="image" src="../../images/icons/cloud.png" alt="Cloud" />
          <span slot="title">Cloud</span>
          <span slot="description">
            Accessible and seamless customer experiences for user-centric cloud
            computing.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/ideas.png"
            alt="Ideas & Innovation"
          />
          <span slot="title">Ideas & Innovation</span>
          <span slot="description">
            Smart solutions, rich in technological insight for optimizing
            revenue-based services in a multitude of hardware platforms.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/device-creation.png"
            alt="Device Creation"
          />
          <span slot="title">Device Creation</span>
          <span slot="description">
            Custom-engineering OS and software stacks for customers optimized
            for usability, connectivity and robustness.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/system.png"
            alt="System Level Engineering"
          />
          <span slot="title">System Level Engineering</span>
          <span slot="description">
            Deep expertise in acceleration, profiling and optimization for
            converged Linux platforms and system-level engineering.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img slot="image" src="../../images/icons/iot.png" alt="IoT" />
          <span slot="title">Internet of Things (IoT) & Connected Devices</span>
          <span slot="description">
            Cross-channel experience design in sensors, hardware and systems.
          </span>
        </ExpertiseItem>
      </div>
      <div>
        <ExpertiseItem>
          <img
            slot="image"
            src="../../images/icons/full-stack.png"
            alt="Full-stack"
          />
          <span slot="title">Full-stack</span>
          <span slot="description">
            Development of all layers of a software project – from the hardware
            and OS kernel all the way through to the graphical user interface.
          </span>
        </ExpertiseItem>
      </div>
    </div>
  </div>
</div>

<style>
  #expertise {
    background-color: var(--color-movial-black);
    background: url(../../images/people.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: var(--space-xl);
  }
  h2 {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xl);
  }
  img {
    height: 70px;
  }
</style>
