<div>
  <div class="image">
    <slot name="image">
      <img src="../../images/icons/ideas.png" alt="Expertise" />
    </slot>
  </div>
  <h3>
    <slot name="title"><span>Title missing</span></slot>
  </h3>
  <p>
    <slot name="description"><span>Description missing</span></slot>
  </p>
</div>

<style>
  @media (min-width: 600px) {
    div {
      padding-right: var(--space-xl);
    }
  }
  h3 {
    padding-bottom: 0px;
    margin-bottom: 13px;
  }

  .image {
    display: flex;
    justify-content: center;
  }

  img {
    height: 70px;
  }
</style>
