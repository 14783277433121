<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
</script>

<div id="get-in-touch">
  <div class={containerCSS}>
    <div class={col2_1000}>
      <div>
        <h2>
          Could workflow automation improve your productivity? Let's talk about
          it!
        </h2>
        <a href="#contact-section">Get in touch</a>
      </div>
      <div>
        <h2>How to achieve a well rounded experience.</h2>
        <p>
          In addition to workflow automation, here at Movial we build great
          solutions by building great relationships with our clients. Whether
          you’re looking to launch a new app, develop a new mobile service,
          write software for a complex business solution, or embed new
          components into an existing solution, we will be there to support you
          from concept to code. Together, we can turn your ideas into
          innovations that are smart, secure and a joy to use.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  #get-in-touch {
    background: #282b51;
    padding-bottom: var(--space-xxl);
    padding-top: var(--space-xxl);
    color: var(--color-dark-grey);
  }

  a {
    background: #1dc0ed 0 0 no-repeat padding-box;
    border-radius: 45px;
    opacity: 1;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 1;
    padding: 22px 44px;
    font-weight: bold;
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 80px;
  }

  h2 {
    color: #fff;
  }

  p {
    color: #fff;
    font-size: var(--font-size-s);
  }
</style>
