import { css } from "@emotion/css";

const col2_1000 = css`
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const col3_1000 = css`
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const col3_all = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export { col2_1000, col3_1000, col3_all };
