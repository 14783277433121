<script>
  import Logo from "../header/Logo.svelte";
  import containerCSS from "../../styles/container";
</script>

<header>
  <div class={containerCSS}>
    <nav>
      <div>
        <Logo scroll={false} />
      </div>
    </nav>
  </div>
</header>

<style>
  header {
    position: fixed;
    top: 0;
    padding-top: 25px;
    width: 100%;
    height: 50px;
    background-color: var(--color-movial-black);
    z-index: 100;
  }
</style>
