import App from "./App.svelte";
import CookieConfig from "./utils/cookieconfig";

import "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

// vanilla-cookieconsent configuration
const cc = window.initCookieConsent();
cc.run(CookieConfig);

const app = new App({
  target: document.body,
});

export default app;
