<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
</script>

<div id="security-page">
  <div class={containerCSS}>
    <h2>Engineering cybersecure, people-first software</h2>
    <p>
      Our world-class security experts have vast experience in cybersecurity.
      They’re also highly-skilled software designers and developers, which
      enables them to build software and systems that are cybersecure by design,
      not as an afterthought. Our approach is to build security into every
      project we work on through continuous threat analysis, architecture-level
      threat mitigation, developing and maintaining a secure programming
      process, and by avoiding common mistakes by automating tooling and
      verification.
    </p>
    <p>
      Making sure that the systems and devices we build don’t just start secure
      but also stay secure takes careful design and constant maintenance. All of
      our developers are highly trained and are always up to date on latest
      developments in the constantly-changing threat landscape. Our processes
      always follow the current best practices and international security
      standards.
    </p>
    <div id="security-cols" class={col2_1000}>
      <div>
        <div>
          <h3>Security Audits and Penetration Testing</h3>
          <p>
            If there’s a way in, attackers will find it. Our security audits and
            testing are designed to keep your solutions, devices and business
            safe – today, tomorrow and in the future.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>White box security audit</h3>
          <p>
            White box testing validates security functionality and whether the
            code implementation follows the design, and uncovers potential
            vulnerabilities. We’ll review the system and application security of
            your architecture, code and technology choices, document the risks,
            and propose cost-effective mitigation strategies.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Basic penetration testing</h3>
          <p>
            Penetration testing identifies vulnerabilities by launching an
            authorized simulated attack. We’ll test your target systems using
            reconnaissance and exploit techniques that follow the best practices
            in penetration testing, document the findings and provide
            suggestions on how to harden the security.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Advanced penetration testing</h3>
          <p>
            This approach goes even deeper to identify the gaps in the target
            systems and network security and even your employee knowledge by
            simulating a targeted attack against your systems. To do that, we’ll
            use custom scripts and/or tools to find vulnerabilities through
            automated testing like fuzzing and other means, developing proof of
            concepts of potential exploits.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Software development process and tooling review</h3>
          <p>
            Code review is the key to build secure software solutions – if the
            code isn’t secure, nothing is. We’ll review the software development
            process and its tooling from a cybersecurity point of view,
            recommending improvements to make sure your code is always secure.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Infrastructure, policy and practices audit</h3>
          <p>
            Often the biggest security risk to organizations are its people and
            practices. We’ll review your information technology infrastructure,
            policies and practices by performing key personnel interviews and
            executing the most relevant manual and/or automated cybersecurity
            scans. Once we’ve identified any vulnerabilities in your
            organization, we’ll suggest ways to improve.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>
            Cybersecurity mitigations and defense-in-depth solutions development
          </h3>
          <p>
            Guaranteeing bug-free software is pretty much impossible, especially
            when you consider that most software projects depend on hundreds, if
            not thousands of 3rd party components. Platform-wide cybersecurity
            mitigations, also known as defense-in-depth solutions, help to
            prevent bugs from becoming exploitable vulnerabilities.
          </p>

          <ul>
            <li>Hardware-based mitigations</li>
            <ul>
              <li>Trusted Execution Environment</li>
              <li>Trusted Platform Module</li>
              <li>Replay Protected Memory Block</li>
              <li>Immutable Root of Trust</li>
              <li>Pointer authentication</li>
              <li>ARM TrustZone, Intel TXT</li>
            </ul>
            <li>Privilege separation architectures</li>
            <li>
              Software architecture design from cybersecurity point of view
            </li>
            <li>
              OS-level system hardening (e.g. SELinux, privilege separation,
              host firewalls, etc.)
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div>
          <h3>Cybersecurity training</h3>
          <p>
            Let our team help your team. We offer peer-to-peer coaching to help
            software teams to develop more secure and robust code and improve
            their software development practices. Courses can be tailored to
            your individual needs and cover a range of cybersecurity topics.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  #security-page {
    background-color: var(--color-light-grey);
    padding-bottom: var(--space-xl);
    color: var(--color-dark-grey);
  }

  h2 {
    margin: 0;
    padding-top: var(--space-xl);
  }

  h3 {
    font-size: var(--font-size-m);
    margin-bottom: var(--space-xs);
  }

  #security-cols p,
  #security-cols li {
    font-size: var(--font-size-s);
    margin-top: 0px;
  }

  #security-cols li {
    padding-top: 2px;
    padding-bottom: 3px;
  }

  @media (min-width: 1000px) {
    #security-cols > div > div {
      margin-right: var(--space-xl);
    }
  }
</style>
