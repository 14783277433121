<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
  import decryptEmail from "../../utils/decryptEmail";

  export let theme = "light";
  export let branches = ["FI"];
</script>

<div id="branch-wrapper" class={theme}>
  <div id="branch" class={containerCSS}>
    <div class={col2_1000}>
      {#if branches.includes("FI")}
        <div class="branch-content">
          <h3>Finland</h3>
          <p>
            Porkkalankatu 20,
            <br />
            FI-00180
            <br />
            Helsinki
            <br />
            Finland
            <br /><br />
            +358 40 772 0172
            <br /><br />
            Sales inquiries
            <br />
            <a href="mailto:{decryptEmail('c2FsZXNAbW92aWFsLmNvbQ==')}"
              >{decryptEmail("c2FsZXNAbW92aWFsLmNvbQ==")}</a
            >
          </p>
        </div>
      {/if}
      {#if branches.includes("US")}
        <div class="branch-content">
          <h3>North America</h3>
          <p>
            3 Twin Dolphin Dr. Suite 125
            <br />
            Redwood City, CA 94065-1516
            <br />
            United States
            <!--
            <br /><br />
            +1-302-566-8425
            -->
          </p>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  #branch-wrapper {
    background-color: var(--color-light-grey);
    color: var(--color-dark-grey);
    padding-bottom: 20px;
  }
  #branch-wrapper.dark {
    background-color: var(--color-movial-dark);
    color: #fff;
  }
  #branch-wrapper.ima {
    background-color: #282b51;
    color: #fff;
  }
  #branch {
    padding-top: var(--space-l);
  }

  .branch-content {
    padding-right: var(--space-m);
  }

  h3 {
    font-size: 26px;
    margin-bottom: 0;
  }
  p {
    line-height: var(--line-height-narrow);
  }
  a {
    color: var(--color-dark-grey);
    font-size: 16px;
  }
  #branch-wrapper.dark a,
  #branch-wrapper.ima a {
    color: #fff;
  }
</style>
