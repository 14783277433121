<script>
  import containerCSS from "../../styles/container";
  import { col3_1000 } from "../../styles/cols";
</script>

<div id="advantages-wrapper">
  <div class={containerCSS}>
    <div id="advantages" class={col3_1000}>
      <div class="advantage-content">
        <h3>1. Kartoitus</h3>
        <p>
          Movialin kyberturvallisuusasiantuntijat kartoittavat yrityksenne
          sovellukset, ohjelmistot ja tietoverkot tietoturvan näkökulmasta ja
          kirjaavat löydökset raporttiin.
        </p>
      </div>
      <div class="advantage-content">
        <h3>2. Toimenpidesuositukset</h3>
        <p>
          Löydettyihin riskeihin ja haavoittuvuuksiin annetaan yksilölliset
          toimenpidesuositukset. Nämä suositukset tehdään laajan järjestelmä- ja
          ohjelmointikokemuksen pohjalta, jossa kiinnitetään huomiota
          kokonaisuuteen.
        </p>
      </div>
      <div class="advantage-content">
        <h3>3. Raportti tietoturvan nykytilanteesta</h3>
        <p>
          Raportti antaa kattavan kuvan yrityksenne tietoturvan nykytilanteesta
          ja selkeät ohjeet tietoturvan kehittämiseksi.<br /><br />
          Yrityksenne parannetun tietoturvan myötä edistätte omaa huoltovarmuuttanne,
          liiketoiminnan jatkuuvuutta ja koko yhteiskunnan resilienssiä.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  #advantages-wrapper {
    background-color: var(--color-movial-dark);
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: #fff;
    text-align: center;
  }

  #advantages > div {
    text-align: left;
  }

  p {
    font-size: var(--font-size-m);
    justify-content: center;
  }

  @media (min-width: 1000px) {
    h3 {
      min-height: 70px;
    }

    #advantages > div {
      text-align: left;
      padding-right: 10%;
      margin-top: 10%;
    }
  }
</style>
