<div class="case">
  <h3>
    <slot name="title"><span>Title missing</span></slot>
  </h3>
  <!--
  <slot name="image">
    <img src="./images/case.jpg" alt="Missing" />
  </slot>
  -->
  <p>
    <slot name="description"><span>Description missing</span></slot>
  </p>
</div>

<style>
  h3 {
    margin-bottom: var(--space-m);
  }

  @media (min-width: 1000px) {
    .case {
      padding-right: var(--space-l);
    }
  }
</style>
