<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
  import decryptEmail from "../../utils/decryptEmail";

  export let theme = "light";
</script>

<div id="contact-wrapper" class={theme}>
  <div class={containerCSS}>
    <div id="contact-cols" class={col2_1000}>
      <div>
        <div class="contact">
          <span>General information</span>
          <a
            href="mailto:{decryptEmail(
              'aW5mbytnZW5lcmFsLWluZm9ybWF0aW9uQG1vdmlhbC5jb20='
            )}"
          >
            {decryptEmail("aW5mbytnZW5lcmFsLWluZm9ybWF0aW9uQG1vdmlhbC5jb20=")}
          </a>
        </div>
        <div class="contact">
          <span>New Business Contacts</span>
          <a
            href="mailto:{decryptEmail(
              'aW5mbytuZXctYnVzaW5lc3MtY29udGFjdHNAbW92aWFsLmNvbQ=='
            )}"
          >
            {decryptEmail(
              "aW5mbytuZXctYnVzaW5lc3MtY29udGFjdHNAbW92aWFsLmNvbQ=="
            )}
          </a>
        </div>
        <div class="contact">
          <span>Press</span>
          <a href="mailto:{decryptEmail('aW5mbytwcmVzc0Btb3ZpYWwuY29t')}"
            >{decryptEmail("aW5mbytwcmVzc0Btb3ZpYWwuY29t")}</a
          >
        </div>
        <div class="contact">
          <span>Shareholder and investor information</span>
          <a
            href="mailto:{decryptEmail(
              'aW5mbytzaGFyZWhvbGRlci1hbmQtaW52ZXN0b3ItaW5mb3JtYXRpb25AbW92aWFsLmNvbQ=='
            )}"
            >{decryptEmail(
              "aW5mbytzaGFyZWhvbGRlci1hbmQtaW52ZXN0b3ItaW5mb3JtYXRpb25AbW92aWFsLmNvbQ=="
            )}</a
          >
        </div>
        <a href="https://movial.breezy.hr/">Careers</a>
      </div>
      <div>
        <a
          rel="noreferrer"
          href="https://www.linkedin.com/company/movial"
          target="_blank"
        >
          <img id="linked-in" src="../../images/LI-In-Bug.png" alt="LinkedIn" />
        </a>
      </div>
    </div>
    <span id="copyright">&copy; 2022 Movial Corporation</span>
  </div>
</div>

<style>
  #contact-wrapper {
    background-color: var(--color-light-grey);
    color: var(--color-dark-grey);
    padding-bottom: var(--space-xl);
  }

  #contact-wrapper.dark {
    background-color: var(--color-movial-dark);
    color: #fff;
  }
  #contact-wrapper.ima {
    background-color: #282b51;
    color: #fff;
  }

  #contact-cols > div {
    padding-bottom: var(--space-xl);
  }

  #contact-cols > div > a {
    padding-right: var(--space-s);
  }

  a {
    color: var(--color-dark-grey);
    display: block;
  }

  #contact-wrapper.dark a,
  #contact-wrapper.ima a {
    color: #fff;
  }

  img#linked-in {
    width: 50px;
  }

  .contact {
    margin-bottom: 20px;
  }

  .contact span {
    padding-bottom: 4px;
    display: block;
  }

  .contact a {
    font-size: 16px;
  }
</style>
