<script>
  import containerCSS from "../../styles/container";
</script>

<div id="intro">
  <div class={containerCSS}>
    <h2>Terms of Use and Privacy Policy</h2>
  </div>
</div>

<style>
  #intro {
    background-color: #282b51;
    background-image: url("../../workflow-automation/images/background.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 85%;
    padding-bottom: 70px;
    height: 30vh;
    display: flex;
    align-items: end;
  }

  h1 {
    font-size: var(--font-size-xxl);
    margin-bottom: var(--space-xl);
    line-height: 125%;
  }
  @media (min-width: 1000px) {
    h1 {
      width: 60%;
    }
  }

  @media (min-width: 1400px) {
    #intro {
      background-image: url("../../workflow-automation/images/background@2x.png");
    }
  }
</style>
