<script>
  import SimpleHeader from "../components/header/SimpleHeader.svelte";
  import Intro from "../components/vulnerability-assessment/Intro.svelte";
  import Services from "../components/vulnerability-assessment/Services.svelte";
  import Credential from "../components/vulnerability-assessment/Credential.svelte";
  import Customers from "../components/customers/Customers.svelte";
  //import Branch from "../components/branch/Branch.svelte";
  import ContactUs from "../components/contact-us/ContactUs.svelte";
  import GetInTouch from "../components/vulnerability-assessment/GetInTouch.svelte";

  const branches = ["US"];
  const language = "en";
  const purpose = "Vulnerability Assessment";
</script>

<SimpleHeader />
<div id="sections">
  <section id="intro-section"><Intro /></section>
  <section id="services-section"><Services /></section>
  <section id="credential-section"><Credential /></section>
  <section id="get-in-touch-section"><GetInTouch /></section>
  <section id="customers-section"><Customers /></section>
  <section id="contact-section">
    <ContactUs theme="dark" {language} {purpose} />
  </section>
  <!--<section id="branch-section"><Branch theme="dark" {branches} /></section>-->
</div>
