<script>
  import containerCSS from "../../styles/container";
  import { col3_all } from "../../styles/cols";

  const customers = [
    { logo: "microsoft", name: "Microsoft" },
    { logo: "hitachi", name: "Hitachi" },
    { logo: "nexstim", name: "Nexstim" },
    { logo: "schneider_electric", name: "Schneider Electric" },
    { logo: "habita", name: "Habita" },
    { logo: "vaisala", name: "Vaisala" },
  ];

  export let language = "en";
</script>

<div id="customers-wrapper">
  <div class={containerCSS}>
    {#if language === "en"}
      <h2>Some of our clients</h2>
    {:else}
      <h2>Asiakkaitamme</h2>
    {/if}
    <div class="{col3_all} logos">
      {#each customers as customer}
        <div>
          <div class="logo">
            <img
              src="../../images/customers/{customer.logo}.png"
              alt={customer.name}
            />
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  #customers-wrapper {
    background-color: #fff;
    padding-bottom: var(--space-xxl);
    padding-top: var(--space-xl);
    color: var(--color-dark-grey);
  }

  h2 {
    padding-top: var(--space-xxl);
    font-size: var(--font-size-xl);
  }

  .logos {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .logo {
    padding-right: 15%;
    padding-left: 15%;
    padding-bottom: 10%;
  }

  img {
    width: 120px;
    max-width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
</style>
