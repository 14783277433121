<script>
  import SimpleHeader from "../components/header/SimpleHeader.svelte";
  import Intro from "../components/industrial-mobile-applications/Intro.svelte";
  import Reason from "../components/industrial-mobile-applications/Reason.svelte";
  import Examples from "../components/industrial-mobile-applications/Examples.svelte";
  import GetInTouch from "../components/industrial-mobile-applications/GetInTouch.svelte";
  import Customers from "../components/customers/Customers.svelte";
  //import Branch from "../components/branch/Branch.svelte";
  import ContactUs from "../components/contact-us/ContactUs.svelte";

  const branches = ["US"];
  const language = "en";
  const purpose = "Industrial Mobile Applications";
</script>

<SimpleHeader />
<div id="sections">
  <section id="intro-section"><Intro /></section>
  <section id="reason-section"><Reason /></section>
  <section id="examples-section"><Examples /></section>
  <section id="get-in-touch-section"><GetInTouch /></section>
  <section id="customers-section"><Customers /></section>
  <section id="contact-section"><ContactUs {language} {purpose} /></section>
  <!--<section id="branch-section"><Branch theme="ima" {branches} /></section>-->
</div>
