<script>
  import containerCSS from "../../styles/container";
</script>

<div id="about-wrapper">
  <div class={containerCSS}>
    <h2>Developing the future from concept to code</h2>
    <p>
      Since 2001 we’ve built thousands of world-class, custom devices and
      systems in mobile and embedded services for forward-thinking companies all
      over the world – from companies of one to some of the biggest of them all
      – including Microsoft, Hitachi, Schneider, Vaisala, T-Mobile and many
      more.
    </p>
    <p>
      We believe cybersecurity should be an integral part of the development
      process, not an afterthought. So we combine expert software development
      skills with cybersecurity insight to create resilient architecture – with
      security and privacy built right into the code. The solutions we engineer
      ensure the optimal functionality and reliability of our customers’
      products and services, helping to strengthen their business.
    </p>
    <p>
      At Movial, we build great solutions by building great relationships with
      our clients. Whether you’re looking to launch a new app, develop a new
      mobile service, write software for a complex business solution, or embed
      new components into an existing solution, we will be there to support you
      from concept to code. Together, we can turn your ideas into innovations
      that are smart, secure and a joy to use.
    </p>
    <div id="from-concept">
      <img
        src="../../images/from_concept_to_code_white.png"
        alt="From concept to code"
      />
    </div>
  </div>
</div>

<style>
  #about-wrapper {
    background-color: var(--color-movial-brand);
    padding-bottom: var(--space-l);
    background-image: url("../../images/ukraine.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  h2 {
    padding-top: var(--space-xxl);
    font-size: var(--font-size-xl);
  }

  p {
    font-size: var(--font-size-m);
  }

  #from-concept {
    text-align: center;
    padding-bottom: 10px;
  }

  img {
    width: 100%;
    max-width: 600px;
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
  }
</style>
