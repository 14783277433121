<script>
  import containerCSS from "../../styles/container";
  import { col3_1000 } from "../../styles/cols";
</script>

<div id="services-wrapper">
  <div class={containerCSS}>
    <div id="services" class={col3_1000}>
      <div class="service-content">
        <h2>Vulnerability Assessment</h2>
        <div class="icon">
          <img
            src="../../images/vulnerability-assessment/icon_VA.png"
            alt="Vulnerability assessment"
          />
        </div>
        <p>
          Current state security assessment based on your individual technology
          stack. This can be done remotely, analyzing the attack surface that is
          visible to an outsider.
        </p>
      </div>

      <div class="service-content">
        <h2>Continuous Monitoring</h2>
        <div class="icon">
          <img
            src="../../images/vulnerability-assessment/icon_CM.png"
            alt="Continuous monitoring"
          />
        </div>
        <p>
          24/7 automated monitoring against attacks or anomalies. An automated
          report will alert you about threats and updates for securing your
          system.
        </p>
      </div>

      <div class="service-content">
        <h2>Mitigation Recommendations</h2>
        <div class="icon">
          <img
            src="../../images/vulnerability-assessment/icon_MR.png"
            alt="Mitigation recommendations"
          />
        </div>
        <p>
          Experienced software developers recommend fixes against attacks based
          on your specific needs.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  #services-wrapper {
    background-color: var(--color-light-grey);
    padding-bottom: var(--space-xl);
    padding-top: var(--space-xxl);
    color: var(--color-movial-dark);
  }
  #services {
    background: #eeeeee 0 0 no-repeat padding-box;
  }

  #services > div {
    text-align: center;
  }

  #services .icon {
    display: inline-block;
    width: 245px;
    height: 245px;
    border-radius: 90%;
    background: #ffffff 0 0 no-repeat padding-box;
  }

  #services h2 {
    font-size: 27px;
  }

  #services .service-content {
    padding-right: 30px;
  }

  #services .icon img {
    position: relative;
    top: 72px;
  }
</style>
