<script>
  import Header from "../components/header/Header.svelte";
  import Intro from "../components/intro/Intro.svelte";
  import About from "../components/about/About.svelte";
  import Cases from "../components/cases/Cases.svelte";
  import Expertise from "../components/expertise/Expertise.svelte";
  import Culture from "../components/culture/Culture.svelte";
  import Customers from "../components/customers/Customers.svelte";
  import Security from "../components/expertise/Security.svelte";
  import SoftwareDevelopment from "../components/expertise/SoftwareDevelopment.svelte";
  import DataAnalytics from "../components/expertise/DataAnalytics.svelte";
  import Branch from "../components/branch/Branch.svelte";
  import Contact from "../components/contact/Contact.svelte";
</script>

<Header />
<div id="sections">
  <section id="intro"><Intro /></section>
  <section id="about"><About /></section>
  <section id="expertise"><Expertise /></section>
  <section id="customers"><Customers /></section>
  <section id="cybersecurity"><Security /></section>
  <section id="softwareDevelopment"><SoftwareDevelopment /></section>
  <section id="dataAnalytics"><DataAnalytics /></section>
  <section id="cases"><Cases /></section>
  <section id="culture"><Culture /></section>
  <section id="branch"><Branch /></section>
  <section id="contact"><Contact /></section>
</div>
