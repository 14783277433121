<script>
  import containerCSS from "../../styles/container";
  import { col2_1000, col3_1000 } from "../../styles/cols";
</script>

<div id="software-development-page">
  <div class={containerCSS}>
    <h2 id="main-title">Building secure software for a connected world</h2>
    <p>
      In the digital world of today, your software needs to be built secure and
      built to adapt. Since 2001, Movial’s skilled and passionate experts have
      been helping businesses of all sizes build secure, custom software
      development solutions. We can help with everything from developing the
      initial idea to implementing and running software systems across all kinds
      of devices, stacks and environments – from embedded, mobile and
      cloud-based services to multimedia and communications systems. Whether
      it’s a turnkey solution or helping you solve a specific software
      development challenge, our team will be there to support your team every
      step of the way.
    </p>
    <h2>
      Today, the only constant is change. Our goal is to make change work for
      you
    </h2>
    <p>
      We’re on a mission to help companies thrive in the digital age. To do
      that, we help you truly understand your customers and build world-class
      service systems and applications that are secure, robust and a joy to use.
      But that knowledge doesn’t come out of nowhere, it’s the result of
      research and insight-gathering that’s geared towards imagining future
      experiences and designing and building services that meet real customer
      needs, inspire your own organization and help your business grow.
    </p>
    <div id="sd-items">
      <div>
        <div>
          <h3>Software project deliveries</h3>
          <p>
            If you can envision it, we can build it. We can build you a complete
            custom turnkey solution, help your own team of experts with a
            specific challenge – or really anything in between. We’ll support
            you with planning and production, support and maintenance, project
            management, process consulting and more.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Embedded services</h3>
          <p>
            Embedded services can help you build innovative connected solutions
            that delight and deliver real value to your customers. Let Movial’s
            experts help you develop and build secure software services that are
            embedded into new and existing products and devices like
            special-purpose industrial, medical and consumer electronic devices.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>The tools we use</h3>
          <p>
            Linux-based embedded devices
            <br />
            FPGA programming Microcontrollers
            <br />
            Full-stack development – from board bring-up to a production ready device
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Mobile devices</h3>
          <p>
            The digital world runs on mobile devices. We’ll help you build
            secure native and cross-platform applications that run on them. And
            if needed, we can also help you build new special-purpose mobile
            devices or customize existing mobile operating systems to meet your
            needs. From Android OS customization to new feature implementation,
            and from things like audio and video component integration to
            building entirely new apps, we’ll be there to support you.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Traditional software</h3>
          <p>
            When it comes to digitalization, the only constant is change. We can
            help you adapt with software that runs natively, across platforms
            and devices. Our skilled and experienced team can build software
            designed to be used by individual consumers or across your entire
            enterprise – in development environments like Linux, Windows UWP,
            MacOS and languages like HTML5 and Qt/QML.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Architecture design</h3>
          <p>
            Your software’s architecture is the foundation for success, and it’s
            all in the planning. Designing and developing software to be
            futureproof helps keep project costs down and ensures that the
            software you’re building will do what it should. Flexible and
            modular software architecture is easier to maintain and extend. But
            is also increases clarity, which makes it much easier to build
            software that’s secure and reliable. Let our experts help you design
            architecture for mobile, embedded, cloud, desktop, microservices and
            more.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>User interface (UI) development</h3>
          <p>
            If you make software that people love, they’ll use it. Our team has
            extensive experience in building human-centered, highly functional
            and lag-free user interfaces that are customized to different device
            types using the best native or cross-platform technology for each
            case. From touchscreen, industrial and cross-device interfaces to
            things like high-performance 3D visualizations, we can help you
            create a world-class user experience.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Cloud-based systems and services</h3>
          <p>
            Movial has extensive expertise in the server, client and
            infrastructure side of software development, as well as in
            integrations, building cloud-native solutions and various hybrid
            solutions. We can help with microservice and API design and
            development on platforms like Amazon Wen Service, Microsoft Azure
            and Heroku.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>
            Tooling and software development process development (DevOps,
            DevSecOps)
          </h3>
          <p>
            Today software development is more of an evolution than a one-time
            delivery. We’ll provide you with tool and technology development
            customized to your needs in various software development situations,
            particularly in embedded and mobile development and cybersecurity.
            We’ll also help you develop custom and also automated tools to test
            performance, power usage and cybersecurity resilience.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>Performance, graphics and power usage optimization</h3>
          <p>
            A key part of delivering a great user experience is building a
            software system that works, looks beautiful and is lag- and
            glitch-free. Our team of experts can help you avoid problems during
            the development process and perform a system-wide profiling and
            optimization analysis to identify and react to problems when they do
            come up.
          </p>
        </div>
      </div>
    </div>

    <div id="key-concepts" class={col3_1000}>
      <div>
        <h3>Research and insight</h3>
        <p>
          We’ll work closely with your management team to help you make a
          successful shift to strategies aimed at delivering lifetime value to
          your customers. Our approach uses qualitative user and technical
          insights and we’ll support you through the strategy process. When it’s
          done, we’ll provide you with a comprehensive report outlining your
          strategic vision and actionable steps on how to achieve it.
        </p>
      </div>

      <div>
        <h3>Conceptualization</h3>
        <p>
          Based on the research findings and market demands, we’ll provide you
          with a range of actionable ideas around meeting customer needs. The
          end result is usually an efficient prototype that utilizes the full
          potential of the target software stack and provides detailed
          specifications for development.
        </p>
      </div>

      <div>
        <h3>System and UI design</h3>
        <p>
          With our vast technical and design experience, we can help you develop
          and build service systems and integrated devices that have passed
          feasibility, viability and desirability testing every step of the way.
          We’ll create visual prototypes of the user interface (UI) design
          concepts and continue tweaking the selected concept throughout the
          prototyping and testing phase.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  #software-development-page {
    padding-bottom: var(--space-xl);
    background-color: var(--color-medium-grey);
  }

  h2#main-title {
    padding-top: var(--space-xxl);
  }

  h3 {
    font-size: var(--font-size-m);
    margin-bottom: var(--space-xs);
  }
  #sd-items p {
    font-size: var(--font-size-s);
    margin-top: 0px;
  }

  #key-concepts {
    margin-top: var(--space-xl);
  }

  #key-concepts h3 {
    font-size: var(--font-size-l);
  }

  @media (min-width: 1000px) {
    #sd-items > div {
      padding-right: var(--space-xl);
    }

    #key-concepts > div {
      list-style: none;
    }

    #key-concepts > div {
      padding-right: var(--space-xl);
    }
  }
</style>
