<script>
  import containerCSS from "../../styles/container";
  import { col2_1000 } from "../../styles/cols";
  import Case from "./Case.svelte";
</script>

<div id="cases-page">
  <div class={containerCSS}>
    <h2>References</h2>
    <div class={col2_1000}>
      <div>
        <Case>
          <span slot="title">Schneider Electric</span>
          <span slot="description">
            Schneider Electric is on a mission to empower people and companies
            to make the most of our energy and resources, bridging progress and
            sustainability for all. <br /><br />With our long-term cooperation
            we are helping Schneider Electric in developing their next
            generation modular, user friendly fire detection panels for
            buildings of all sizes. Working as a part of the software team, we
            were involved in implementing the UI, kernel-level and FPGA
            programming. We also provided training and guidance in architectural
            decisions.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">Microsoft</span>
          <span slot="description">
            Microsoft is a world leader in computer software, computers and
            related services.<br /><br />When Microsoft began planning their
            next generation concept device, Surface Duo, a dual-touchscreen
            Android device that is a part of the Microsoft Surface series of
            touchscreen hardware devices, we supported them in realizing their
            vision of this new product category.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">KEVA</span>
          <span slot="description">
            KEVA is Finland’s largest pension provider, administering the
            pensions of public sector employees. For them and their customers,
            quality cybersecurity is an absolute must.<br /><br />
            Movial has supported KEVA by providing world-class cybersecurity expertise,
            including a risk-driven, privacy-focused investigation into the cybersecurity
            of one of their services, resulting in a concrete list of identified
            risks and actionable steps on how to improve. We also supported the KEVA
            team with in-depth design and verification of algorithmic cybersecurity
            hardening and penetration testing of enterprise-class wireless networking
            infrastructure.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">Habita</span>
          <span slot="description">
            Habita was born in Finland back in 1989 and since then has grown
            into a global real estate operator specializing in residential,
            commercial and property brokerage.<br /><br />
            Our ongoing co-operation with Habita focuses on automating the global
            real estate network’s workflow and creating a scalable, cloud-based software
            platform that connects the work of agents all over the world and coordinates
            marketing and support functions. The system is designed to work on mobile
            and desktop so that teams can reach their shared goals together. We are
            supporting Habita with strategy and architecture, CX and conceptual design,
            DevOps, front-end for both mobile and desktop, and more.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">Vaisala Liquid Measurements</span>
          <span slot="description">
            Vaisala Liquid Measurements (K-Patents) is a leading manufacturer of
            liquid analyzers and process refractometers for the control and
            optimization of industrial processes.<br /><br />Movial helped
            Vaisala (K-Patents) to build a next generation refractometer for
            in-factory use to measure, control and operate processes involving
            liquid substances. The device is a Linux-based computer connected
            with multiple peripheral boards, hardware modules and sensors and
            controlled via a touch screen user interface.<br /><br />Our tasks
            span from technical architecture design, BSP integration, custom
            data architecture implementation (including e.g., different software
            daemons, CAN messaging, applications etc.) to design of intuitive
            user experience and implementation of appealing user interface.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">Hitachi High-Tech Analytical Science</span>
          <span slot="description">
            Hitachi High-Tech Analytical Science is a global leader in the
            design and manufacturing of high-tech tools for industry and
            research.<br /><br />
            In our long-term and ongoing partnership, we are helping Hitachi High-Tech
            Analytical Science’s product development with their family of handheld
            measurement tools optimized for portability, fast start-up time and ease
            of use in the analysis of metal concentrates for on-the-spot inspection.<br
            /><br />We have designed a custom Microservices-oriented
            architecture and a separate HTML5-based UI with WebSockets for
            interfacing modules and used JSON as the language and
            platform-neutral data interchange. Paired with the customer’s own
            APIs, this enables the easy implementation of remote management
            scenarios and manufacturing line-level automation.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">Nexstim</span>
          <span slot="description">
            Nexstim develops world-leading targeted neuro-stimulation technology
            as personalized and effective therapies and diagnostics for
            challenging brain diseases and disorders. Movial has supported the
            Nexstim team along the R&D journey, helping with both UX design and
            software development across many phases of the design and
            development process.
          </span>
        </Case>
      </div>
      <div>
        <Case>
          <span slot="title">Wonderia</span>
          <span slot="description">
            Wonderia is in a mission of offering new innovative environment for
            learning. Daring to think in a new way is Wonderia's driving force.
            The possibility of free expression and raising awareness in an
            inspiring way are Wonderia's core values.<br /><br />Movial designed
            and produced a virtual learning environment for Wonderia. It is a
            unique combination of online course platform, online coaching,
            events and the learning community. This WordPress-based application
            will bring a new kind of innovative learning and teaching experience
            for its users.
          </span>
        </Case>
      </div>
    </div>
  </div>
</div>

<style>
  h2 {
    padding-top: var(--space-xl);
  }

  #cases-page {
    background-color: var(--color-light-grey);
    color: var(--color-dark-grey);
    padding-bottom: var(--space-l);
  }
</style>
