<script>
  import SimpleHeader from "../components/header/SimpleHeader.svelte";
  import Intro from "../components/tietoturvaseteli/intro.svelte";
  import Reason from "../components/tietoturvaseteli/Reason.svelte";
  import Advantages from "../components/tietoturvaseteli/Advantages.svelte";
  import Customers from "../components/customers/Customers.svelte";
  import ContactUs from "../components/contact-us/ContactUs.svelte";
  import Branch from "../components/branch/Branch.svelte";

  const branches = ["FI"];
  const language = "fi";
  const purpose = "Tietoturvaseteli";
</script>

<SimpleHeader />
<div id="sections">
  <section id="intro-section"><Intro /></section>
  <section id="reason"><Reason /></section>
  <section id="advantages"><Advantages /></section>
  <section id="customers"><Customers {language} /></section>
  <section id="contact-us"><ContactUs {language} {purpose} /></section>
  <section id="branch-section"><Branch theme="dark" {branches} /></section>
</div>
